import styled, { css, createGlobalStyle } from 'styled-components';

const Text = styled.span`
	display: inline-block;
	font-family: 'Public Sans', sans-serif;
	${(props) =>
		props.color &&
		css`
			color: ${props.color};
		`}
	${(props) => css`
		font-size: ${props.size ? props.size / 10 : 1.4}rem;
		font-weight: ${props.weight || 'normal'};
	`}
    ${(props) => css`
		margin-top: ${props.mt ? props.mt / 10 : 0}rem;
		margin-bottom: ${props.mb ? props.mb / 10 : 0}rem;
		margin-left: ${props.ml ? props.ml / 10 : 0}rem;
		margin-right: ${props.mr ? props.mr / 10 : 0}rem;
	`}
	${(props) =>
		props.width &&
		css`
			width: ${props.width / 10}rem;
		`}
	${(props) =>
		props.align &&
		css`
			text-align: ${props.align};
		`}

		${(props) =>
		props.wrap &&
		css`
			white-space: ${props.wrap};
		`}

		${(props) =>
		props.lineHeight &&
		css`
			line-height: ${props.lineHeight};
		`}
`;

export const ReponsiveContainer = createGlobalStyle`
    html {
        font-size: 62.5%;
    }



    body {
        font-size: 1.4rem;
        overflow-x: hidden;
    }
`;

export default Text;
