import React, { createElement, lazy, Suspense } from 'react';

const Page = lazy(() => import('./Page'));
const Brands = lazy(() => import('./Organisms/Brands'));

const Header = lazy(() => import('./Organisms/Header'));
const Footer = lazy(() => import('./Organisms/Footer'));
const WhyBetaI = lazy(() => import('./Organisms/WhyBetaI'));
const CollaborativeInnovation = lazy(() =>
	import('./Organisms/CollaborativeInnovation')
);
const Hero = lazy(() => import('./Organisms/Hero'));
const OurOffices = lazy(() => import('./Organisms/OurOffices'));
const WeBelieve = lazy(() => import('./Organisms/WeBelieve'));
const FindTheSolutions = lazy(() => import('./Organisms/FindTheSolutions'));
const OurImpact = lazy(() => import('./Organisms/OurImpact'));
const FollowOurBlog = lazy(() => import('./Organisms/FollowOurBlog'));
const Newsletter = lazy(() => import('./Organisms/Newsletter'));
const BlogCover = lazy(() => import('./Organisms/BlogCover'));
const Contact = lazy(() => import('./Templates/Contact'));
const Blog = lazy(() => import('./Templates/Blog'));
const Modal = lazy(() => import('./Organisms/Modal'));
const OtherCases = lazy(() => import('./Organisms/OtherCases'));
const TestimonialBanner = lazy(() => import('./Organisms/TestimonialBanner'));
const ExplorePosts = lazy(() => import('./Organisms/ExplorePosts'));
const SharePost = lazy(() => import('./Organisms/SharePost'));
const Testimonial = lazy(() => import('./Molecules/Testimonial'));
const BlogTitle = lazy(() => import('./Atoms/BlogTitle'));
const Post = lazy(() => import('./Templates/Post'));
const Case = lazy(() => import('./Templates/Case'));
const ParagraphSeeOpenings = lazy(() =>
	import('./Molecules/ParagraphSeeOpenings')
);
const SingleParagraph = lazy(() => import('./Molecules/SingleParagraph'));
const MoreAboutUs = lazy(() => import('./Molecules/MoreAboutUs'));
const WeBelievePeople = lazy(() => import('./Organisms/WeBelievePeople'));
const WorkBenefits = lazy(() => import('./Organisms/WorkBenefits'));
const OurPlace = lazy(() => import('./Organisms/OurPlace'));
const DayByDay = lazy(() => import('./Molecules/DayByDay'));
const CareersHero = lazy(() => import('./Organisms/CareersHero'));
const CareersVideo = lazy(() => import('./Organisms/CareersVideo'));
const OurOpenings = lazy(() => import('./Organisms/OurOpenings'));
const OurSuperpowers = lazy(() => import('./Organisms/OurSuperpowers'));
const Cases = lazy(() => import('./Templates/Cases'));
const MeetOurPeople = lazy(() => import('./Organisms/MeetOurPeople'));
const ParagraphWithImage = lazy(() => import('./Molecules/ParagraphWithImage'));
const AboutUsText = lazy(() => import('./Molecules/AboutUsText'));

const BetaiInNumbers = lazy(() => import('./Organisms/BetaiInNumbers'));
const WhatWeStandFor = lazy(() => import('./Organisms/WhatWeStandFor'));
const OurKey = lazy(() => import('./Organisms/OurKey'));
const IndustriesText = lazy(() => import('./Organisms/IndustriesText'));
const FormToLeadCapture = lazy(() => import('./Organisms/FormToLeadCapture'));
const OurServicesColsSection = lazy(() =>
	import('./Organisms/OurServicesColsSection')
);
const OurServicesHero = lazy(() => import('./Organisms/OurServicesHero'));
const GlobalInnovators = lazy(() => import('./Organisms/GlobalInnovators'));
const HowCanYouCountOnUs = lazy(() => import('./Templates/HowCanYouCountOnUs'));
const LegislationContent = lazy(() => import('./Templates/LegislationContent'));
const ColItemCase = lazy(() => import('./Molecules/ColItemCase'));
const VideoPlayer = lazy(() => import('./Molecules/VideoPlayer'));
const DarkBanner = lazy(() => import('./Organisms/LandingPage/DarkBanner'));
const ImageText = lazy(() => import('./Organisms/LandingPage/ImageText'));
const TextContent = lazy(() => import('./Organisms/LandingPage/TextContent'));
const PurpleBanner = lazy(() => import('./Organisms/LandingPage/PurpleBanner'));
const Form = lazy(() => import('./Organisms/LandingPage/Form'));
const VideoImg = lazy(() => import('./Organisms/LandingPage/VideoImg'));
const PeopleArea = lazy(() => import('./Organisms/LandingPage/PeopleArea'));
const MainSlider = lazy(() => import('./Organisms/LandingPage/MainSlider'));
const RelatedContentBtn = lazy(() =>
	import('./Organisms/LandingPage/RelatedContentBtn')
);
const RelatedContent = lazy(() =>
	import('./Organisms/LandingPage/RelatedContent')
);
const Resources = lazy(() => import('./Organisms/Resources'));
const StartupsRelatedContent = lazy(() =>
	import('./Organisms/Startups/StartupsRelatedContent')
);
const StartupsTestimonial = lazy(() =>
	import('./Organisms/Startups/StartupsTestimonial')
);
const StartupsBenefits = lazy(() =>
	import('./Organisms/Startups/StartupsBenefits')
);
const StartupsNumbers = lazy(() =>
	import('./Organisms/Startups/StartupsNumbers')
);
const StartupsCarouselIcon = lazy(() =>
	import('./Organisms/Startups/StartupsCarouselIcon')
);
const StartupsJobs = lazy(() => import('./Organisms/Startups/StartupsJobs'));
const Programs = lazy(() => import('./Organisms/Programs'));

const Components = {
	page: Page,
	theme: Header,
	hero_slider: Hero,
	footer: Footer,
	why_betai: WhyBetaI,
	collaborative_innovation: CollaborativeInnovation,
	our_impact: OurImpact,
	our_offices: OurOffices,
	brands: Brands,
	we_believe: WeBelieve,
	follow_our_blog: FollowOurBlog,
	find_solutions: FindTheSolutions,
	contact_modal: Modal,
	newsletter: Newsletter,
	background_contact: Contact,
	blog_title: BlogTitle,
	blog_cover: BlogCover,
	blog_container: Blog,
	case_content: Case,
	testimonial: Testimonial,
	other_cases: OtherCases,
	testimonial_banner: TestimonialBanner,
	explore_posts: ExplorePosts,
	post_content: Post,
	share_post: SharePost,
	hero_careers: CareersHero,
	paragraph_see_openings: ParagraphSeeOpenings,
	video_careers: CareersVideo,
	work_benefits: WorkBenefits,
	we_believe_people: WeBelievePeople,
	single_paragraph: SingleParagraph,
	our_superpowers: OurSuperpowers,
	day_by_day: DayByDay,
	our_openings: OurOpenings,
	our_place: OurPlace,
	know_more_about_us: MoreAboutUs,
	cases_content: Cases,
	meet_our_people: MeetOurPeople,
	paragraph_banner_about: ParagraphWithImage,
	betai_in_numbers: BetaiInNumbers,
	about_us_text: AboutUsText,
	what_we_stand_for: WhatWeStandFor,
	our_key: OurKey,
	industries_text: IndustriesText,
	cta_form_report: FormToLeadCapture,
	our_services_hero: OurServicesHero,
	columns_section: OurServicesColsSection,
	global_innovators: GlobalInnovators,
	our_services_main: HowCanYouCountOnUs,
	legislation_content: LegislationContent,
	row_item: ColItemCase,
	video_player: VideoPlayer,
	lp_dark_banner: DarkBanner,
	lp_image_text: ImageText,
	lp_text_content: TextContent,
	lp_purple_banner: PurpleBanner,
	lp_form: Form,
	lp_media: VideoImg,
	lp_people: PeopleArea,
	lp_slider: MainSlider,
	lp_related_content: RelatedContent,
	related_content_btn: RelatedContentBtn,
	content_resources: Resources,
	startups_related_content: StartupsRelatedContent,
	startups_testimonial: StartupsTestimonial,
	startups_benefits: StartupsBenefits,
	startups_numbers: StartupsNumbers,
	startups_slider_icon: StartupsCarouselIcon,
	startups_jobs: StartupsJobs,
	content_programs: Programs,
};

export default (blok) => {
	if (typeof Components[blok.component] !== 'undefined') {
		const parameters = {
			key: blok._uid,
			content: blok,
			context: blok.context,
		};

		return (
			<Suspense fallback={<div />}>
				{createElement(Components[blok.component], parameters)}
			</Suspense>
		);
	}

	console.log(`The component ${blok.component} has not been created yet.`);
};
