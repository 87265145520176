import React, { useState } from 'react';

export const MenuContext = React.createContext({});

export const MenuProvider = (props) => {
	const [navItems, setNavItems] = useState([]);

	return (
		<MenuContext.Provider value={{ navItems, setNavItems }}>
			{props.children}
		</MenuContext.Provider>
	);
};

export const useMenu = () => React.useContext(MenuContext);
