import SbEditable from 'storyblok-react';
import React from 'react';

import { useParams } from 'react-router-dom';

import { gql } from 'apollo-boost';
import { Query } from 'react-apollo';
import Components from './index';
import { NewsletterModal } from './Organisms/NewsletterModal';
import { omit } from '../helpers/lodashAlternatives';
import { Helmet } from 'react-helmet';

export default () => {
	const { slug, lang } = useParams();

	const language = lang
		? lang
		: sessionStorage.getItem('lang') !== null
		? `${sessionStorage.getItem('lang')}/`
		: '';

	const url = language ? `${language}/blog/${slug}` : `blog/${slug}`;

	const query = gql`
		query {
			PostItem(id: "${url}") {
				id
				tag_list
        name
				content {
					_uid
					image {
						filename
						alt
					}
					title
					tag
					pdf_view
					title_of_long_text
					long_text
					image_type
					image_content {
						filename
						alt
					}
					continuation_long_text
					subtitle
					subtext
					videos
					seo
					body
					context
				}
				first_published_at
				full_slug
			}
		}
	`;

	return (
		<Query query={query}>
			{(result) => {
				if (result.loading) return null;
				if (result.error) return null;

				const post = result.data.PostItem;
				if (!post) return 'Post não encontrado!';

				const bgColor =
					post.content.context[0].color === 'light' ? `#F7F5F2` : `#02000A`;

				document.body.style.backgroundColor = bgColor;
				document.title =
					post.content?.seo?.title ||
					`${post.name} - Beta-i | Collaborative Innovation`;

				const currentLang = sessionStorage.getItem('lang');
				const portugueseVersion = currentLang === 'pt-br';
				const uri = 'https://beta-i.com';
				const slug = post.full_slug;
				const url = portugueseVersion ? `${uri}${slug}` : `${uri}/${slug}`;

				return (
					<SbEditable content={post.content}>
						<Helmet>
							<link rel='canonical' href={url} />
							<title>
								{post?.content?.seo?.title |
									`${post.name} - Beta-i | Collaborative Innovation`}
							</title>
							<meta
								property='og:title'
								content={
									post?.content?.seo?.title ||
									`${post?.name} - Beta-i | Collaborative Innovation`
								}
							/>
							<meta
								name='description'
								content={
									post?.content?.seo?.description ||
									"We are a collaborative innovation consultancy with global reach. We're experts in managing corporate open innovation, pilot-oriented projects with highly-curated startups, and designing products and services alongside in-company teams."
								}
							/>
							<meta
								property='og:description'
								content={
									post?.content?.seo?.description ||
									"We are a collaborative innovation consultancy with global reach. We're experts in managing corporate open innovation, pilot-oriented projects with highly-curated startups, and designing products and services alongside in-company teams."
								}
							/>
							<meta
								property='og:image'
								content={
									post?.content?.image?.filename ||
									'https://a.storyblok.com/f/124990/1200x630/cc47fbd3f8/beta-i-website-share.png'
								}
							/>
							<meta
								http-equiv='Content-Language'
								content={portugueseVersion ? 'pt-BR' : 'en-US'}
							/>
						</Helmet>
						<NewsletterModal />
						<div>
							{post.content.body.map((blok) =>
								Components({
									...blok,
									isPage: false,
									post: omit(post.content, 'body'),
									published_at: post.first_published_at,
									full_slug: post.full_slug,
									body: post.content.body,
									tag_list: post.tag_list,
								})
							)}
						</div>
					</SbEditable>
				);
			}}
		</Query>
	);
};
