import styled from 'styled-components';
import colors from '../../tokens/colors';

export const Overlay = styled.div`
	z-index: 11;
	background: rgba(0, 0, 0, 0.4);
	position: fixed;
	top: 0;
	right: 0;
	left: 0;
	bottom: 0;
	display: ${({ showModal }) => (showModal ? 'flex' : 'none')};
	flex-direction: column;
	justify-content: center;
	align-items: center;
	overflow: hidden;
	width: 100%;
	height: 100%;
	padding: 0;

	@media (max-width: 1024px) {
		display: none;
	}
`;

export const Container = styled.div`
	width: 85%;
	max-width: 1120px;
	height: 80%;
	max-height: 652px;
	display: flex;
	align-items: center;
	background-color: ${colors.primary.lilac};
	position: relative;
	box-shadow: 4px 32px 80px rgba(0, 0, 0, 0.1);
	border-radius: 32px 0px 0px 32px;
`;

export const CloseModal = styled.div`
	width: 54px;
	height: 54px;
	border-radius: 50%;
	background: ${colors.offWhite};
	padding: 1.2rem;

	background-image: url(${({ src }) => src});
	background-position: center;
	background-size: 40px;

	position: absolute;
	right: -1rem;
	top: -2rem;
	cursor: pointer;
`;

export const BackgroundImg = styled.div`
	width: 40%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;

	> img {
		height: 85%;
		max-width: 90%;
	}
`;

export const BoxInformations = styled.div`
	width: 60%;
	height: 100%;
	background-color: #000;

	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	padding: 0 96px;
`;

export const Heading3 = styled.h3`
	font-size: 40px;
	font-weight: 300;
	color: ${colors.primary.aqua};
	margin-bottom: 3.2rem;

	span.white-text {
		color: ${colors.offWhite};
	}
`;

export const Form = styled.div`
	width: 100%;

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	form {
		display: flex;
		flex-wrap: wrap;
		column-gap: 0.92rem;
	}

	.hs-firstname,
	.hs-lastname {
		width: 49%;
	}

	.hs-submit {
		width: 100%;
	}

	.submitted-message {
		color: ${colors.offWhite};
		font-size: 4rem;
	}
`;
