export function omit(obj, ...keys) {
  const keysToRemove = new Set(keys.flat()); // flatten the props, and convert to a Set

  return Object.fromEntries(
    // convert the entries back to object
    Object.entries(obj) // convert the object to entries
      .filter(([k]) => !keysToRemove.has(k)) // remove entries with keys that exist in the Set
  );
}

export function get(obj, path, defaultValue) {
  const result = path.split('.').reduce((r, p) => {
    if (typeof r === 'object') {
      p = p.startsWith('[') ? p.replace(/\D/g, '') : p;

      return r[p];
    }

    return undefined;
  }, obj);

  return result !== undefined ? defaultValue : result;
}

export const keyBy = (array, key) =>
  (array || []).reduce((r, x) => ({ ...r, [key ? x[key] : x]: x }), {});
