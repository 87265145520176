import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'swiper/swiper-bundle.css';
import 'swiper/swiper.min.css';
import 'swiper/components/navigation/navigation.min.css';
import smoothscroll from 'smoothscroll-polyfill';

import ApolloClient, { InMemoryCache } from 'apollo-boost';
import { ApolloProvider } from 'react-apollo';
import { LeadCaptureProvider } from './providers/leadCapture';
import { MenuProvider } from './providers/menuContext';

navigator.sayswho = (function () {
	var ua = navigator.userAgent;
	var tem;
	var M =
		ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) ||
		[];

	if (/trident/i.test(M[1])) {
		tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
		return 'IE ' + (tem[1] || '');
	}

	if (M[1] === 'Chrome') {
		tem = ua.match(/\b(OPR|Edge)\/(\d+)/);
		if (tem != null) return tem.slice(1).join(' ').replace('OPR', 'Opera');
	}

	M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
	if ((tem = ua.match(/version\/(\d+)/i)) != null) M.splice(1, 1, tem[1]);
	return M;
})();

if (navigator.sayswho[0] === 'Safari' && navigator.sayswho[1] <= 14) {
	smoothscroll.polyfill();
}

export const client = new ApolloClient({
	uri: 'https://gapi.storyblok.com/v1/api',
	cache: new InMemoryCache(),
	request: (operation) => {
		operation.setContext({
			headers: {
				token: 'OQiIqQtzgTIl0B3TROlY0Att',
				version: 'published',
			},
		});
	},
});

ReactDOM.render(
	<React.StrictMode>
		<ApolloProvider client={client}>
			<LeadCaptureProvider>
				<MenuProvider>
					<App />
				</MenuProvider>
			</LeadCaptureProvider>
		</ApolloProvider>
	</React.StrictMode>,
	document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
