import SbEditable from 'storyblok-react';
import React from 'react';

import { useParams } from 'react-router-dom';

import { gql } from 'apollo-boost';
import { Query } from 'react-apollo';
import Components from './index';
import { NewsletterModal } from './Organisms/NewsletterModal';
import { omit } from '../helpers/lodashAlternatives';
import { Helmet } from 'react-helmet';

export default () => {
	const { slug } = useParams();
	const pathname = window.location.pathname;

	const parent = pathname.replace(slug, '');

	const url = parent + slug;

	const query = gql`
		query {
			LandingpageItem(id: "${url}") {
				id
				full_slug
        name
				content {
					_uid
					language
					filters
					body 
					context
					cover_img {
						filename
					}
					seo
				}
			}
		}
	`;

	return (
		<Query query={query}>
			{(result) => {
				if (result.loading) return null;
				if (result.error) return null;

				const lpContent = result.data.LandingpageItem;
				if (!lpContent) return 'Página não encontrada!';

				const bgColor =
					lpContent.content.context[0].color === 'light'
						? `#F7F5F2`
						: `#02000A`;

				document.body.style.backgroundColor = bgColor;

				const currentLang = sessionStorage.getItem('lang');
				const portugueseVersion = currentLang === 'pt-br';
				const uri = 'https://beta-i.com';
				const slug = lpContent.full_slug;
				const url = portugueseVersion ? `${uri}${slug}` : `${uri}/${slug}`;

				return (
					<SbEditable content={lpContent.content}>
						<Helmet>
							<link rel='canonical' href={url} />
							<title>
								{lpContent?.content?.seo?.title |
									`${lpContent.name} - Beta-i | Collaborative Innovation`}
							</title>
							<meta
								property='og:title'
								content={
									lpContent?.content?.seo?.title ||
									`${lpContent?.name} - Beta-i | Collaborative Innovation`
								}
							/>
							<meta
								name='description'
								content={
									lpContent?.content?.seo?.description ||
									"We are a collaborative innovation consultancy with global reach. We're experts in managing corporate open innovation, pilot-oriented projects with highly-curated startups, and designing products and services alongside in-company teams."
								}
							/>
							<meta
								property='og:description'
								content={
									lpContent?.content?.seo?.description ||
									"We are a collaborative innovation consultancy with global reach. We're experts in managing corporate open innovation, pilot-oriented projects with highly-curated startups, and designing products and services alongside in-company teams."
								}
							/>
							<meta
								property='og:image'
								content={
									lpContent?.content?.cover_img?.filename ||
									'https://a.storyblok.com/f/124990/1200x630/cc47fbd3f8/beta-i-website-share.png'
								}
							/>
							<meta
								http-equiv='Content-Language'
								content={portugueseVersion ? 'pt-BR' : 'en-US'}
							/>
						</Helmet>
						<NewsletterModal />
						<div>
							{lpContent.content.body.map((blok) =>
								Components({
									...blok,
									isPage: false,
									lpContent: omit(lpContent.content, 'body'),
									published_at: lpContent.first_published_at,
									full_slug: lpContent.full_slug,
									body: lpContent.content.body,
								})
							)}
						</div>
					</SbEditable>
				);
			}}
		</Query>
	);
};
