import React from 'react';
import styled from 'styled-components';
import { ReponsiveContainer } from './components/Atoms/Text';
import colors from './components/tokens/colors';

import Routes from './routes';
import { HubspotProvider } from '@aaronhayes/react-use-hubspot-form';

function App() {
	return (
		<HubspotProvider>
			<Base>
				<ReponsiveContainer />

				<Routes />
			</Base>
		</HubspotProvider>
	);
}

const Base = styled.div`
	color: ${colors.black};
`;

export default App;
