import React, { useState } from 'react';

export const LeadCaptureContext = React.createContext({});

export const LeadCaptureProvider = (props) => {
	const [pagePostState, setPagePostState] = useState({
		tag: '',
		date: '',
	});

	return (
		<LeadCaptureContext.Provider value={{ pagePostState, setPagePostState }}>
			{props.children}
		</LeadCaptureContext.Provider>
	);
};

export const useLeadCapture = () => React.useContext(LeadCaptureContext);
