import React, { useEffect, useState } from 'react';
import { useHubspotForm } from '@aaronhayes/react-use-hubspot-form';
import {
	Overlay,
	Container,
	CloseModal,
	BackgroundImg,
	BoxInformations,
	Heading3,
	Form,
} from './style';
import closeIcon from '../../../assets/images/icon_close_black.png';
import { gql, useQuery } from '@apollo/client';
import { client } from '../../..';
import { MARK_STYLED, render } from 'storyblok-rich-text-react-renderer';

const lang = sessionStorage.getItem('lang');
const slug = lang
	? `${lang}/global/newsletter-modal`
	: 'global/newsletter-modal';

const query = gql`
	{
		GlobalItem(id: "${slug}") {
			content {
				_editable
				_uid
				component
				content
			}
		}
	}
`;

export const NewsletterModal = () => {
	const [showModal, setShowModal] = useState(false);
	const { data } = useQuery(query, { client: client });
	const content = data?.GlobalItem?.content?.content?.[0];

	useHubspotForm({
		region: 'na1',
		portalId: '545338',
		formId: lang
			? '64892e81-701a-4c5b-966e-692ab5133395'
			: '931393cb-9a2d-4487-bd0e-5dd2de2ef3f0',
		target: '#newsletter-modal-form',
		onFormSubmitted: function () {
			document.querySelector('.newsletter-text').style.display = 'none';
		},
	});

	const handleCloseModal = () => {
		localStorage.setItem('newsletter-modal', false);
		setShowModal(false);
	};

	useEffect(() => {
		if (!localStorage.getItem('newsletter-modal')) {
			setTimeout(() => {
				setShowModal(true);
			}, 25000);
		}
	}, []);

	return (
		<Overlay className='newsletter-modal' showModal={showModal}>
			<Container>
				<CloseModal
					src={closeIcon}
					onClick={handleCloseModal}
					className='close-btn'
				/>
				<BackgroundImg>
					<img
						src={content?.background?.filename}
						alt={content?.background?.alt || 'Imagem com cor sólida de fundo'}
					/>
				</BackgroundImg>
				<BoxInformations>
					<Heading3 className='newsletter-text'>
						{render(content?.text, {
							markResolvers: {
								[MARK_STYLED]: (children, props) => (
									<span className={props?.class}>{children}</span>
								),
							},
						})}
					</Heading3>
					<Form id='newsletter-modal-form' className='hbspt-form'></Form>
				</BoxInformations>
			</Container>
		</Overlay>
	);
};
