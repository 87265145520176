import React from 'react';

import { BrowserRouter, Switch, Route } from 'react-router-dom';

import { gql } from 'apollo-boost';
import { Query } from 'react-apollo';

import { useEffect } from 'react';
import useGeoLocation from 'react-ipgeolocation';
import ScrollToTop from './helpers/scrollToTop';
import Components from './components';
import Post from './components/Post';
import { Fragment } from 'react';
import LandingPage from './components/LandingPage';

const query = gql`
	query ($lang: String) {
		PageItems(starts_with: $lang) {
			items {
				id
				name
				full_slug
				path
				lang
				published_at
				content {
					_uid
					component
					body
					context
				}
			}
			total
		}
	}
`;

export default function Routes(props) {
	const location = useGeoLocation();
	const hasManualLanguage = sessionStorage.getItem('manual-lang');

	if (location.country === 'BR' && !hasManualLanguage) {
		sessionStorage.setItem('lang', 'pt-br');
	} else if (!hasManualLanguage) {
		sessionStorage.removeItem('lang');
	}

	useEffect(() => {
		if (location.country) {
			if (location.country === 'BR' && !hasManualLanguage) {
				sessionStorage.setItem('lang', 'pt-br');
				const path = window.location.pathname;
				if (path.indexOf('/pt-br') === -1) {
					window.location.href = `/pt-br${path}`;
				}
			} else if (location.country !== 'BR' && !hasManualLanguage) {
				const path = window.location.pathname;
				if (path.indexOf('/pt-br') !== -1) {
					window.location.href = path.replace('/pt-br', '');
				}
			}
		}
	}, [location.country, hasManualLanguage]);

	const language =
		sessionStorage.getItem('lang') !== null
			? `${sessionStorage.getItem('lang')}/`
			: '';

	return (
		<BrowserRouter>
			<ScrollToTop>
				<Switch>
					<Route key='post' path='/:lang/blog/:slug' component={Post} />
					<Route key='post2' path='/blog/:slug' component={Post} />

					<Route
						key='lp'
						path='/:lang/resources/:slug'
						component={LandingPage}
					/>
					<Route key='lp2' path='/resources/:slug' component={LandingPage} />

					<Route
						key='lp-solutions'
						path='/:lang/solutions/:slug'
						component={LandingPage}
					/>
					<Route
						key='lp2-solutions'
						path='/solutions/:slug'
						component={LandingPage}
					/>

					<Route
						key='lp-startups'
						path='/:lang/startups/:slug'
						component={LandingPage}
					/>
					<Route
						key='lp2-startups'
						path='/startups/:slug'
						component={LandingPage}
					/>

					<Query query={query} variables={{ lang: language }}>
						{(result) => {
							if (result.loading) return null;
							if (result.error) return null;

							const pages = result.data.PageItems.items.filter((item) => {
								return item.content.body && item.content.component === 'page';
							});

							return pages.map((pageItem, index) => {
								const pageRoute = pageItem.path || '/' + pageItem.full_slug;

								return (
									<Fragment key={pageItem?.path + index}>
										<Route key={pageItem.id} exact path={pageRoute}>
											{Components({ ...pageItem.content, ...pageItem })}
										</Route>

										{pageRoute === '/' && (
											<Route key={pageItem.id + '-br'} exact path={'/pt-br'}>
												{Components({ ...pageItem.content, ...pageItem })}
											</Route>
										)}
									</Fragment>
								);
							});
						}}
					</Query>
				</Switch>
			</ScrollToTop>
		</BrowserRouter>
	);
}
