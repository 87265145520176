export default {
	primary: {
		aqua: `#00E595`,
		lilac: `#9C3CE8`,
	},
	secondary: {
		aqua100: `#00DA77`,
		aqua200: `#02D374`,
		aqua300: `#01C56C`,
		aqua800: `#00B865`,

		lilac100: `#8F39D2`,
		lilac200: `#8232C0`,
		lilac400: `#732CAB`,
		lilac800: `#612490`,

		blue: `#5738C1`,

		error: `#E83C5B`,
	},
	black: `#02000A`,
	offWhite: `#F7F5F2`,
	white: `#FFFFFF`,
	grey: `#E5E5E5`,

	lilacAquamarine: `linear-gradient(90deg, #9C3CE8 0%, #00E595 100%);`,
};
